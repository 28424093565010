import {Contact} from '@shared/contact/contact';
import {SelectableModel} from '@app/component/autocomplete/abstract-autocomplete/selectable-model';
import {WithContact} from '@shared/contact/with-contact';
import {Country} from '@shared/country/country';
import {UserType} from '@shared/user/user-type-enum';
import {Role} from '@shared/user/role';
import {Group} from '@shared/user/group';

export class User extends SelectableModel implements WithContact {

  username!: string;
  /* active=null indicates the user was not found in kc. */
  active!: boolean|null;
  contact?: Contact;
  type!: UserType;
  headquarters!: boolean;
  countries!: Country[];

  // Values set by the UserResolver
  role?: Role | null;
  groups: Group[] = [];

  /**
   * Returns the display value of the active status:
   * - active === true: <empty string>
   * - active === false: (disabled)
   * - active === null: (<valueForNull>) or (?)
   */
  getActiveDisplayValue(valueForNull: string = '?'): string {
    return this.active == null ? `(${valueForNull})` : (this.active ? '' : '(disabled)');
  }

  /**
   * Returns the username + the display value of the active field
   */
  get usernameDisplayValue(): string {
    return `${this.username} ${this.getActiveDisplayValue()}`.trim();
  }
}
