<mat-toolbar>
  <mat-toolbar-row>
    <a [routerLink]="['/dashboard']" class="logo">
      <img class="image" src="assets/100weeks_logo.png" alt="100weeks logo" id="1000weeks"/>
    </a>

    <div class="main-menu">
      <mat-list class="header-app-toolbar-list">
        <mat-list-item routerLinkActive="active" [routerLink]="['/dashboard']">
          <mat-icon [svgIcon]="icons.DASHBOARD"></mat-icon>
          Dashboard
        </mat-list-item>
        <ng-container *ngxPermissionsOnly="[permissions.ROLE_LEVEL_20]">
          <mat-list-item *ngIf="sessionInfo.user && !(sessionInfo.user.localPartners)" routerLinkActive="active" [routerLink]="['/localpartners']">
            <mat-icon [svgIcon]="icons.LOCAL_PARTNER"></mat-icon>
            Local partners
          </mat-list-item>
          <mat-list-item *ngIf="sessionInfo.user && sessionInfo.user.localPartners && sessionInfo.user.localPartners.length > 0" routerLinkActive="active" [routerLink]="['/localpartners', sessionInfo.user.localPartners[0].uuid]">
            <mat-icon [svgIcon]="icons.LOCAL_PARTNER"></mat-icon>
            {{sessionInfo.user.localPartners[0].name}}
          </mat-list-item>
        </ng-container>
        <mat-list-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_20]" routerLinkActive="active" [routerLink]="['/beneficiarygroups']">
          <mat-icon [svgIcon]="icons.BENEFICIARY_GROUP"></mat-icon>
          Groups
        </mat-list-item>
        <mat-list-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_20]" routerLinkActive="active" [routerLink]="['/beneficiaries']">
          <mat-icon [svgIcon]="icons.BENEFICIARY"></mat-icon>
          Beneficiaries
        </mat-list-item>
        <mat-list-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_20]" routerLinkActive="active" [routerLink]="['/surveys']">
          <mat-icon [svgIcon]="icons.SURVEY"></mat-icon>
          Surveys
        </mat-list-item>
        <mat-list-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_20]" routerLinkActive="active" [routerLink]="['/funding/moneybar']">
          <mat-icon [svgIcon]="icons.MONEYBAR"></mat-icon>
          Moneybar
        </mat-list-item>
        <mat-list-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_40]"
                       [matMenuTriggerFor]="donationMenu"
                       routerLinkActive="active">
          <mat-icon>
            <i class="fa-solid fa-hand-holding-heart"></i>
          </mat-icon>Donations
          <mat-menu #donationMenu="matMenu" yPosition="below">
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/donations']">
              <mat-icon>
                <i class="fa-solid fa-hand-holding-heart"></i>
              </mat-icon>Donations
            </button>
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/payments']">
              <mat-icon>
                <i class="fa-solid fa-coin-vertical"></i>
              </mat-icon>Payments
            </button>
          </mat-menu>
        </mat-list-item>

        <mat-list-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_80]"
                       [matMenuTriggerFor]="batchPaymentMenu"
                       routerLinkActive="active">
          <mat-icon [svgIcon]="icons.BATCH_PAYMENT"></mat-icon>
          Batch Payments
          <mat-menu #batchPaymentMenu="matMenu" yPosition="below">
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/batchpayments/upload']">
              <mat-icon>
                <i class="fas fa-solid fa-file-arrow-up"></i>
              </mat-icon>Upload
            </button>
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/batchpayments/suggestion']">
              <mat-icon [svgIcon]="icons.BATCH_PAYMENT_SUGGESTION"></mat-icon>
              Payment Suggestion
            </button>
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/batchpayments/validation']">
              <mat-icon [svgIcon]="icons.BATCH_PAYMENT_VALIDATION"></mat-icon>
              Batch Validation
            </button>
          </mat-menu>
        </mat-list-item>

        <mat-list-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_20]"
                       [matMenuTriggerFor]="organisationMenu"
                       routerLinkActive="active">
          <mat-icon [svgIcon]="icons.ORGANISATION"></mat-icon>Organisations
          <mat-menu #organisationMenu="matMenu" yPosition="below">
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/organisations']">
              <mat-icon [svgIcon]="icons.ORGANISATION"></mat-icon>Organisations
            </button>
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/projects']">
              <mat-icon>
                <i class="fa-regular fa-chart-gantt fa-lg"></i>
              </mat-icon>Projects
            </button>
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/quests']">
              <mat-icon>
                <i class="fa-regular fa-route fa-lg"></i>
              </mat-icon>Quests
            </button>
            <button mat-menu-item
                    routerLinkActive="active"
                    [routerLink]="['/fieldaffiliates']">
              <mat-icon>
                <i class="fa-regular fa-clipboard-user fa-lg"></i>
              </mat-icon>Field Affiliates
            </button>
          </mat-menu>
        </mat-list-item>

        <mat-list-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_20]" routerLinkActive="active" [routerLink]="['/workflow/tasks']">
          <mat-icon [svgIcon]="icons.WORKFLOW_TASK"></mat-icon>
          Tasks
        </mat-list-item>
      </mat-list>
    </div>

    <div class="user-profile" *ngIf="this.sessionInfo">
      <div [matMenuTriggerFor]="userMenu">
        <mat-icon [svgIcon]="icons.USER"></mat-icon>
        <div>{{sessionInfo.firstName}} {{sessionInfo.lastName}}</div>
      </div>
      <mat-menu #userMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="myAccount()">
          <mat-icon [svgIcon]="icons.PROFILE"></mat-icon>
          <span>Profile</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon [svgIcon]="icons.LOGOUT"></mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>

    <div class="admin-menu" *ngxPermissionsOnly="[permissions.ROLE_LEVEL_40]">
      <button mat-icon-button [matMenuTriggerFor]="adminMenu"><mat-icon [svgIcon]="icons.ADMIN"></mat-icon></button>
      <mat-menu #adminMenu="matMenu" yPosition="below">
        <button mat-menu-item
                *ngxPermissionsOnly="[permissions.ROLE_LEVEL_60]"
                routerLinkActive="active"
                [routerLink]="['/countries']">
          <mat-icon>
            <i class="fas fa-regular fa-earth-africa fa-lg"></i>
          </mat-icon>Countries
        </button>
        <button mat-menu-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_80]" routerLinkActive="active" [routerLink]="['/questionnaires']">
          <mat-icon [svgIcon]="icons.QUESTIONNAIRE"></mat-icon>
          <span>Questionnaires</span>
        </button>
        <button mat-menu-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_80]" routerLinkActive="active" [routerLink]="['/questions']">
          <mat-icon [svgIcon]="icons.QUESTION"></mat-icon>
          <span>Questions</span>
        </button>
        <button mat-menu-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_80]" routerLinkActive="active" [routerLink]="['/questioncategories']">
          <mat-icon [svgIcon]="icons.QUESTION_CATEGORY"></mat-icon>
          <span>Question categories</span>
        </button>
        <button mat-menu-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_60]" routerLinkActive="active" [routerLink]="['/workflow/watchprofiles']">
          <mat-icon [svgIcon]="icons.WATCH_PROFILE"></mat-icon>
          <span>Watch profiles</span>
        </button>
        <button mat-menu-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_40]" routerLinkActive="active" [routerLink]="['/tasks/definitions']">
          <mat-icon [svgIcon]="icons.TASK_DEFINITION"></mat-icon>
          <span>Task definitions</span>
        </button>
        <button mat-menu-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_40]" routerLinkActive="active" [routerLink]="['/tasks/executions']">
          <mat-icon [svgIcon]="icons.TASK_EXECUTION"></mat-icon>
          <span>Task executions</span>
        </button>
        <button mat-menu-item *ngxPermissionsOnly="[permissions.ROLE_LEVEL_40]" routerLinkActive="active" [routerLink]="['/users']">
          <mat-icon [svgIcon]="icons.USERS"></mat-icon>
          <span>Users</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<router-outlet></router-outlet>
