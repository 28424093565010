export class Permissions {
  public static readonly APPLICATION_ONBOARDING = 'application_onboarding-app';
  public static readonly APPLICATION_CENTRAL = 'application_central-app';

  public static readonly ROLE_LEVEL_PREFIX = 'Level_';
  public static readonly ROLE_LEVEL_20 = Permissions.ROLE_LEVEL_PREFIX + 20;
  public static readonly ROLE_LEVEL_40 = Permissions.ROLE_LEVEL_PREFIX + 40;
  public static readonly ROLE_LEVEL_60 = Permissions.ROLE_LEVEL_PREFIX + 60;
  public static readonly ROLE_LEVEL_80 = Permissions.ROLE_LEVEL_PREFIX + 80;
  public static readonly ROLE_LEVEL_100 = Permissions.ROLE_LEVEL_PREFIX + 100;

  // User groups
  public static readonly GROUP_CENTRAL = '100Weeks-user';
  public static readonly GROUP_ONBOARDING_APP = '100Weeks-onboarding-app-user';

}
