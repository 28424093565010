export class Icons {
  public static readonly ADMIN = 'cog';
  public static readonly BATCH_PAYMENT = 'bank';
  public static readonly BATCH_PAYMENT_SUGGESTION = 'calendar-range';
  public static readonly BATCH_PAYMENT_VALIDATION = 'bookmark-check';
  public static readonly BENEFICIARY = 'human-female';
  public static readonly BENEFICIARY_GROUP = 'account-supervisor';
  public static readonly DASHBOARD = 'view-dashboard-variant';
  public static readonly DONATION = 'hand-heart';
  public static readonly LOGOUT = 'run';
  public static readonly LOCAL_PARTNER = 'account-supervisor-circle';
  public static readonly MONEYBAR = 'gauge';
  public static readonly ORGANISATION = 'domain';
  public static readonly PROFILE = 'account-details';
  public static readonly QUESTIONNAIRE = 'script-text-outline';
  public static readonly QUESTION = 'frequently-asked-questions';
  public static readonly QUESTION_CATEGORY = 'animation-outline';
  public static readonly SURVEY = 'clipboard-list';
  public static readonly TASK_DEFINITION = 'calendar-clock';
  public static readonly TASK_EXECUTION = 'calendar-text-outline';
  public static readonly USER = 'account';
  public static readonly USERS = 'account-multiple-outline';
  public static readonly WORKFLOW_TASK = 'clipboard-check';
  public static readonly WATCH_PROFILE = 'file-find-outline';

  // Actions
  public static readonly DELETE = 'delete';
  public static readonly WAIT = 'timer-sand-empty';

}
