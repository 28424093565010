import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private defaultOptions: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    };

    constructor(
      private snackBar: MatSnackBar
    ) {    }

    public info(message: string, title: string, options?: MatSnackBarConfig): void {
      this.snackBar.open(message, 'X', {
        ...this.defaultOptions,
        ...options,
        panelClass: ['info-snackbar']
      });
    }

    public error(message: string, title: string, options?: MatSnackBarConfig): void {
      this.snackBar.open(message, 'X', {
        ...this.defaultOptions,
        ...options,
        panelClass: ['error-snackbar']
      });
    }

    public warning(message: string, title: string, options?: MatSnackBarConfig): void {
      this.snackBar.open(message, 'X', {
        ...this.defaultOptions,
        ...options,
        panelClass: ['warning-snackbar']
      });
    }

    public success(message: string, title: string | undefined, options?: MatSnackBarConfig) {
      this.snackBar.open(message, 'X', {
        ...this.defaultOptions,
        ...options,
        panelClass: ['success-snackbar']
      });
    }

  public clear(): void {
    this.snackBar.dismiss();
  }
}
