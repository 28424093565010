import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {BaseModel} from './base-model';
import {SearchRequest} from '@core/base/objects/search/search-request';
import {SearchResult} from '@core/base/objects/search/search-result';

export abstract class BaseApi<M extends BaseModel> {
  protected endpoint: string;

  constructor(
    protected httpClient: HttpClient,
    path: string
  ) {
    this.endpoint = `${environment.endpointUrl}/${path}`;
  }

  abstract create(): M;

  public findAll(): Observable<M[]> {
    return this.httpClient.get<M[]>(this.endpoint).pipe(
      map(result => result.map(value => Object.assign(this.create(), value)))
    );
  }

  public findByUuid(uuid: string): Observable<M> {
    return this.httpClient.get<M>(`${this.endpoint}/${uuid}`).pipe(map(value => {
      return Object.assign(this.create(), value);
    }));
  }

  public findByRequest(searchRequest: SearchRequest): Observable<SearchResult<M>> {
    return this.httpClient.post<SearchResult<M>>(`${this.endpoint}/find`, searchRequest).pipe(
      tap(result => result.data = result.data.map(value => Object.assign(this.create(), value)))
    );
  }

  public save(model: M): Observable<URL | null | M> {
    return this.httpClient.post<M>(`${this.endpoint}`, model, {observe: 'response'}).pipe(
      map(response => {
        if (response.body && response.body.uuid) {
          return Object.assign(this.create(), response.body);
        }

        const locationOfCreatedObject = response.headers.get('location');
        return locationOfCreatedObject ? new URL(locationOfCreatedObject) : null;
      })
    );
  }

  public update(model: M): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.endpoint}/${model.uuid}`, model);
  }

  public delete(uuid: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.endpoint}/${uuid}`);
  }
}
