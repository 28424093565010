import {Component} from '@angular/core';
import {SessionService} from '@core/services/session.service';
import {SessionInfo} from '@core/objects/session-info';
import {Permissions} from '@core/constants/permissions';
import {Icons} from '@core/constants/icons';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'central';

  sessionInfo: SessionInfo = {
    user: null,
    roles: []
  };

  icons: typeof Icons = Icons;
  permissions: typeof Permissions = Permissions;

  constructor(
    private sessionService: SessionService,
    private keycloakService: KeycloakService,
  ) {
    sessionService.sessionInfo$.subscribe(value => {
      this.sessionInfo = value;
    });
  }

  logout() {
    this.sessionService.logout();
  }

  myAccount() {
    // TODO: Find a nicer way to do this, instead of via the global window
    window.location.href = this.keycloakService.getKeycloakInstance().createAccountUrl();
  }
}
