export enum SearchOperator {
  BEGINS = 'BEGINS',
  BETWEEN = 'BETWEEN',
  CONTAINS = 'CONTAINS',
  IN = 'IN',
  EQ = 'EQ',
  NE = 'NE',
  GE = 'GE',
  GT = 'GT',
  LE = 'LE',
  LT = 'LT',
  IS_NULL = 'IS_NULL',
  IS_NOT_NULL = 'IS_NOT_NULL',
  CUSTOM = 'CUSTOM',
  JOIN = 'JOIN',
}
