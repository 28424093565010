import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {SessionInfo} from '@core/objects/session-info';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {NgxPermissionsService} from 'ngx-permissions';
import {UserApi} from '@shared/user/user-api.service';
import {catchError} from 'rxjs/operators';
import {CurrentUser} from '@shared/user/current-user';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionInfoSubject: BehaviorSubject<SessionInfo>;

  public get sessionInfo(): SessionInfo {
    return this.sessionInfoSubject.getValue();
  }

  public get sessionInfo$(): Observable<SessionInfo> {
    return this.sessionInfoSubject.asObservable();
  }

  constructor(
    private keycloakService: KeycloakService,
    private permissionsService: NgxPermissionsService,
    private userApi: UserApi,
  ) {
    this.sessionInfoSubject = new BehaviorSubject<SessionInfo>(this.createEmptySessionInfo());

    // Watch keycloak events
    keycloakService.keycloakEvents$.subscribe((event) => {
      // Do we need to handle more events?
      if (event.type === KeycloakEventType.OnAuthSuccess) {
        this.updateSession(keycloakService.getKeycloakInstance().profile);
      }
    });

    this.keycloakService.loadUserProfile().then(profile => {
      this.updateSession(profile);
    }, () => {
      console.log('Error while loading profile');
    });
  }

  private updateSession(profile: Keycloak.KeycloakProfile | undefined) {
    const sessionInfo: SessionInfo = {
      user: null,
      username: profile?.username,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      roles: this.keycloakService.getUserRoles(true),
    };

    this.updatePermissions(sessionInfo.roles);
    this.userApi.getCurrentUser()
      .pipe(
        catchError(err => {
          console.error('Error occurred, going to log out', err);
          this.logout();
          throw err;
        })
      )
      .subscribe((user: CurrentUser) => {
        if (user.username !== profile?.username) {
          throw new Error('Username from keycloak and backend do not match');
        }
        sessionInfo.user = user;
        this.sessionInfoSubject.next(sessionInfo);
      });
  }

  private clearSession(): void {
    const emptySessionInfo: SessionInfo = this.createEmptySessionInfo();
    this.sessionInfoSubject.next(emptySessionInfo);
    this.updatePermissions([]);
  }

  private updatePermissions(roles: string[]) {
    // Clear the old roles
    this.permissionsService.flushPermissions();

    // Set the new roles
    this.permissionsService.loadPermissions(roles);
  }

  public hasRole(role: string[]): boolean {
    if (!this.keycloakService.getUserRoles() || this.keycloakService.getUserRoles().length === 0) {
      console.log('User has no roles, or the session is not yet initialized.');
      return false;
    }
    if (!role || role.length === 0) {
      // No roles to check
      return true;
    }

    for (const r of role) {
      if (this.keycloakService.getUserRoles().indexOf(r) !== -1) {
        return true;
      }
    }

    return false;
  }

  public logout(): void {
    this.keycloakService.logout().then(() => this.clearSession());
  }

  private createEmptySessionInfo() {
    return {
      user: null,
      roles: [],
    };
  }
}
